.container{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: #f5f5f5;
}

.Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    background-color: #f5f5f5 ;
    color: white;
    font-size: calc(10px + 2vmin);
    margin-top: 51px;
  }

.container a {
    text-decoration: none;
    color: #000;
}

.content a {
    text-decoration: none;
    color: white;
}


.icon {
    width: 4rem;
    height: 4rem;
    margin-bottom: 20px;
    margin-right: 2rem;
    color: white;
}

.header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 50px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.header h1 {
    font-size: 1.5rem;
    font-weight: 500;
    
    color: #9966CC;
    justify-content: center;
  
}

.content {
    width: 100%;
    height: auto;
    margin-top: 51px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    scroll-behavior: smooth;
}
 

.content a {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100px;
    height: 100px;
    background-color: #9966CC;
    margin: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.name {
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
    color: #fff;
}

.price {
    font-size: 0.8rem;
    font-weight: 500;
    margin: 0;
    color: #fff;
}


.widget {
    width: 45%;
    height: 45%;
    margin-left: 20px;
    margin-right: 20px; 
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #9966CC;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0,0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem;
}

.widget h1 {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0;
    color: #fff;
}

.price {
    font-size: 1rem;
    font-weight: 500;
    color: #fff;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.icons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 20px;
}

.icons a {
    text-decoration: none;
    color: #fff;
}


.button {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 10px 20px;
    border-color: #9666c6;
    background: #f5f5f5;
    border-radius: 30px;
    color:#9666c6 ;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-left: 1rem;
    margin-right: 1rem;
}


.button:hover {
    background: #9666c6;
    color: #f5f5f5;
}

.button:active {
    background: #9666c6;
    color: #f5f5f5;
}

.add {
    margin-left: auto;
    margin-bottom: auto;
}

.back {
   margin-right: auto;
   margin-left: 2rem;
   margin-top: 1rem;
}

.icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-top: 1rem;
    color: #9966CC;
}


.add-car {
    
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    padding: 10px 20px;
    border-color: #9666c6;
    background: #f5f5f5;
    border-radius: 30px;
    color:#9666c6 ;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.container input {
   margin: 0;
   width: 100%;
    padding: 10px 20px;
    border: none;
    background: white;
    border-radius: 30px;
    color:#9666c6 ;
    border: none;
    border-radius: 5px;
   font-size: 16px;
    margin-top: 1rem; 
    margin-bottom: 1rem;
}

.container input:focus {
    outline: none;
}

.container input::placeholder {
    color: #9666c6;
}

.container button {
    margin: 0;
}

.inquire {
    background-color: #fff;
    border-radius: 5px;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    margin-bottom: 20px;
}


.inquireText {
    font-weight: 600;
    font-size: 18px;
    color: #454d65;
}

.back-icon {
    cursor: pointer;
    margin-right: 50%;
    width: 1.5rem;
    height: 1.5rem;
    margin-top: 1rem;
    color: #454d65;
}

.login {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    padding: 10px 20px;
    border-color: #9666c6;
    background: #f5f5f5;
    border-radius: 30px;
    color:#9666c6 ;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}


.login input {
    margin: 0;
    width: 100%;
    padding: 10px 20px;
    border: none;
    background: white;
    border-radius: 30px;
    color:#9666c6 ;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.login input:focus {
    outline: none;
}

.login input::placeholder {
    color: #9666c6;
}

.login form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.login button {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    padding: 10px 20px;
    border-color: #9666c6;
    background: #f5f5f5;
    border-radius: 30px;
    color:#9666c6 ;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.signOutButton {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 80%;
    margin-right: 1rem;
    padding: 10px 20px;
    border-color: #9666c6;
    background: #9666c6 ;
    border-radius: 30px;
    color: #f5f5f5 ;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}